<template>
  <div class="main">
    <heads v-show="!isInWx" :more="showMore" :msg="title" :coin-btn="true" :coin-btn-title="recordTitle"></heads>
    <div v-if="showPage" class="content">
      <div class="topInfo">
        <div class="info1" @click="goCoins">
          <p class="have">已有{{ scoreName }}</p>
          <p class="coin">{{ score }}</p>
        </div>
        <div class="info2">
          <img src="@/static/images/my_index/goodsIcon.png" alt="">
        </div>
        <div class="info3">
          <p class="p1">签到领积分</p>
          <p class="p2">兑换有<span style="color:#ee2e2e;">惊喜</span></p>
          <span class="exchangeBtn" @click="exchangeAction">去兑换</span>
        </div>
      </div>
      <div v-if="showPage" class="signBox daySign">
        <div class="style_flex div_card_top" style="justify-content: space-between">
          <p class="p1">每日签到</p>
          <div class="style_flex div_card">
            <img src="@/static/bu_qian_img.png" alt="">
            <span style="flex-shrink: 0;">补签卡  {{cardNum}} 张</span>
          </div>
        </div>
        <div class="itemBox">
          <div v-for="day in daySigns" class="item" @click="buQian(day)">
            <span v-if="day.is_sign === 0" class="coins">+{{ day.score }}</span>
            <img v-else class="signed"  src="@/static/images/my_index/is_signed.png" alt="">
            <span class="day"  v-if="day.is_repair == 1 && day.is_sign == 0"  style="color: #ee2e2e;text-decoration:underline">补签</span>
            <span class="day" v-else>{{ day.day }}天</span>
          </div>
        </div>
        <div class="signBtn">
          <span v-if="isSigned === 1" class="signedBtn">已经连续签到{{ days }}天</span>
          <span v-else class="signBtn1" @click="signNow">立即签到</span>
        </div>
      </div>
      <div v-if="showPage" class="signBox  signBoxBu">
        <div class="style_flex div_card_top" style="justify-content: space-between">
          <p class="p1">补签任务</p>
          <div class="style_flex div_card" v-show="false">
            <img src="@/static/bu_qian_img.png" alt="">
            <span style="flex-shrink: 0;">补签卡 {{cardNum}} 张</span>
          </div>
        </div>
        <div class="itemBox style_flex div_buqian"  style="justify-content: space-between">
          <img src="@/static/hard_invite.png" alt="">
          <div class="div_content">
            <div class="div_title">邀请好友注册&nbsp;<span>+{{invite_num}}&nbsp;补签卡</span></div>
            <div class="div_tip">每邀请1位新用户注册，可获得{{invite_num}}张补签卡，好友可获得积分奖励</div>
          </div>
          <span class="exchangeBtn" style="flex-shrink: 0;" @click="inviteFriend">去邀请</span>
        </div>
      </div>

      <div class="taskBox">
        <div class="top">
          <p>新手任务</p>
        </div>
        <div v-for="(item,index) in tasklist" class="items">
          <div class="left">
            <img :src="require(`@/static/images/my_index/task_icon${index+1}.png`)" alt="">
          </div>
          <div class="middle">
            <p class="title">
              {{ item.name }}
              <span class="get">+{{ item.score }}{{ scoreName }}</span>
            </p>
            <p class="info">{{ item.desc }}</p>
          </div>
          <div class="right">
            <span v-if="item.is_complete === 1" class="doneBtn">已完成</span>
            <span v-else class="doBtn" @click="doTask(item)">去完成</span>
          </div>
        </div>
      </div>
      <!-- 服务号弹框 -->
      <van-popup v-model="showQrcodeAlert">
        <div class="qrcode_box">
          <div class="qrcode_box_bg">
            <div class="qrcode_text">{{ qrCodeTitle }}</div>
            <img :src="qrCodeUrl" alt="" class="qrcode_img">
            <div class="qrcode_text1">截图保存微信扫码关注领取奖励</div>
          </div>
          <div class="close">
            <img src="@/static/images/my_index/alert_close.png" alt="" @click="closeQRAlert">
          </div>
        </div>
      </van-popup>
    </div>
    <el-dialog width="320px" top="35vh" :visible.sync="showShareCard">
      <div slot="title" class="dialog-header">
        <div class="title">邀请卡片：</div>
      </div>
      <div class="style_flex" style="justify-content: center;flex-direction: column">
        <img class="img_card" :src="invite_bg_url" alt="">
        <div class="signBtn" style="margin-bottom: 8px">
          <span v-if="isInWx" class="sp_tip">长按邀请卡片，转发给朋友</span>
          <span v-else class="signBtn1" style="padding:  0 12px;background: #ee2e2e" @click="downImg('','邀请卡片')">下载邀请卡片，发送给朋友</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { newTaskIndex, signToday,repaireSign, getRegImg } from '@/api/regInfo'
import Cookie from 'js-cookie'
import { wexinShare } from '@/utils/wxShare.js'
import tool from '@/utils/tools.js'

export default {
  components: {
    heads
  },
  data() {
    return {
      uid: Cookie.get('uid') ? Cookie.get('uid') : 0,
      showMore: false,
      title: '任务中心',
      recordTitle: '',
      showPage: false,
      score: 0,
      scoreName: '',
      daySigns: [],
      isSigned: 0,
      days: 0,
      tasklist: [],
      showQrcodeAlert: false,
      qrCodeTitle: '',
      qrCodeUrl: '',

      isInWx: false,
      cardNum:0,
      invite_num:0,
      showShareCard: false,
      invite_bg_url:'',

    }
  },
  created() {
    const a = this.isInWeiXin()
    console.log(a)
    document.getElementsByTagName('title')[0].innerText = '任务中心'
    for (var i = 0; i < document.getElementsByTagName('meta').length; i++) {
      if (document.getElementsByTagName('meta')[i].name == 'description') {
        document.getElementsByTagName('meta')[i].content = '完成新手任务，领取大礼包'
      }
    }
    const wx = navigator.userAgent.toLowerCase()
    if (wx.match(/MicroMessenger/i) == 'micromessenger') {
      // 判断是否已经授权
      this.isInWx = true
    } else {
      this.isInWx = false
    }
  },
  beforeDestroy() {
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    buQian(item){
      if (item.is_repair == 1 && item.is_sign == 0){
        if(this.cardNum >0){
          if(item.is_repair == 1){
            let params = {
              day:item.day
            }
            repaireSign(params).then((res) => {
              if(res.errNo == 0){
                this.$toast("补签成功")
              }
              this.getInfo()
            }).catch((err) => {
              this.getInfo()
            });
          }
        }else {
          this.$toast('您没有补签卡，请完成下方的补签任务');
        }
      }
    },
    downImg() {
      const link = document.createElement('a');
      link.href = this.invite_bg_url;
      link.download = 'invate_card.png'; // 下载后的文件名
      link.click();
    },
    inviteFriend(){
      if (tool.isInApp()) {
        // App分享
        var url = window.location.origin + '/ucenter/inviteRegister?invite_uid='+Cookie.get('uid')
        url = encodeURIComponent(url)
        var pic = 'https://newoss.zhulong.com/forum/202401/23/11/1705980013627058.jpg'
        pic = encodeURIComponent(pic)
        const post =
                'title=哈喽，我邀请您注册筑龙学社，领积分兑换惊喜豪礼！' +
                '&content=完成新用户注册，领取积分换豪礼！还有更多新人福利等着你哦！'+
                '&url=' + url + '&thumb=' + pic
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_shareAction.postMessage(post)
        } else {
          this.$jsbridge.callHandler(
                  'vue_shareAction',
                  post,
                  function(responseData) {
                    // android 返参数接受
                  }
          )
        }
        // todo 分享
      }else if (this.invite_bg_url){
        this.showShareCard = true
      }

    },
    goCoins() {
      // 筑龙币记录
      this.$router.push({ path: '/coin', query: { name: this.recordTitle }})
    },
    // 判断是否是微信浏览器的函数
    isInWeiXin() {
      // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase()
      console.log(JSON.stringify(ua))
      // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    getInfo() {
      let url = window.location.origin + '/ucenter/inviteRegister?invite_uid='+Cookie.get('uid')
      getRegImg({url:url}).then(res =>{
        const errNo = res.errNo
        if (errNo === 0) {
          this.invite_bg_url = res.result
        }
      });
      newTaskIndex().then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.showPage = true
          const result = res.result
          this.score = result.score
          this.cardNum = result.cardNum || 0
          console.log(this.cardNum,"-------")
          this.invite_num = result.invite_num
          this.scoreName = result.scoreName
          this.recordTitle = this.scoreName + '记录'
          this.isSigned = result.is_sign
          this.daySigns = result.daySigns
          const tasks = result.tasklist
          this.tasklist = tasks
          this.days = result.signDay
        } else if (errNo === 2001) {
          // 需要登录
          const url = window.location.href
          this.$router.push({ path: '/go_login', query: { redirect: url }})
        }
      })
    },
    // 去兑换
    exchangeAction() {
      this.$router.push('/gift')
    },
    // 立即签到
    signNow() {
      const post = {
        uid: this.uid
      }
      signToday(post).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          this.$toast('签到成功')
          this.getInfo()
        } else {
          const msg = res.result
          this.$toast(msg)
        }
      })
    },
    // 完任务
    doTask(item) {
      const act = item.act
      console.log(act)
      if (act === 1) {
        // 上传头像
        this.uploadAvatar()
      } else if (act === 2) {
        // 完善信息
        this.writeInformation()
      } else if (act === 3) {
        // 添加关注
        this.addAttention()
      } else if (act === 4) {
        // 关注筑龙服务号
        this.addFuWuHao(item.qrcode)
      } else if (act === 5) {
        // 加社群
        this.addBBSGroup(item.qrcode)
      }
    },
    // 上传头像
    uploadAvatar() {
      this.$router.push({ path: '/regInfo', query: { step: 1 }})
    },
    // 完善信息
    writeInformation() {
      this.$router.push({ path: '/regInfo', query: { step: 2 }})
    },
    // 添加关注
    addAttention() {
      // 先完成个人信息
      const item = this.tasklist[1]
      if (item.is_complete === 1) {
        this.$router.push({ path: '/regInfo', query: { step: 3 }})
      } else {
        this.$toast('请先完善个人信息，才能做此任务')
      }
    },
    // 关注筑龙服务号
    addFuWuHao(qrcode) {
      // 加服务号
      this.qrCodeUrl = qrcode
      this.qrCodeTitle = '筑龙学社服务号'
      this.showQrcodeAlert = true
    },
    // 加社群
    addBBSGroup(qrcode) {
      // 先完成个人信息
      const item = this.tasklist[3]
      if (item.is_complete === 1) {
        // 加社群
        this.qrCodeUrl = qrcode
        this.qrCodeTitle = '筑龙社群'
        this.showQrcodeAlert = true
      } else {
        this.$toast('请先关注筑龙服务号，才能做此任务')
      }
    },
    // 关闭
    closeQRAlert() {
      this.showQrcodeAlert = false
    },
    // 打开微信
    openWinXin(url, type) {
      // if (this.isInWeiXin()) {
      //   var msg = '请在微信里关注筑龙学社公众号'
      //   if (type === '2') {
      //     msg = '请在微信里添加社群'
      //   }
      // } else {
      //   if (tool.isInApp()) {
      //     // app
      //     const url = 'type=1&url=weixin://'
      //     if (tool.isInIosApp()) {
      //       window.webkit.messageHandlers.vue_OpenThirdApp.postMessage(url)
      //     } else {
      //       this.$jsbridge.callHandler(
      //         'vue_OpenThirdApp'
      //         , url
      //         , function (responseData) { }
      //       )
      //     }
      //   } else {
      //     // 浏览器
      //     window.location.href = url
      //   }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
  position: relative;
}
.content {
  background-color: #f4f4f4;
  padding-bottom: 100px;
}
.topInfo {
  width: 100%;
  height: 200px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center;
  .info1 {
    width: calc(100% - 450px);
    height: calc(100% - 60px);
    margin: 30px auto;
    padding-left: 26px;
    border-right: 1px solid #e6e6e6;
    .have {
      font-family: PingFangSC-Regular;
      font-size: 28px;
      color: #666666;
      text-align: left;
    }
    .coin {
      margin-top: 20px;
      font-family: DINAlternate-Bold;
      font-size: 72px;
      color: #222222;
    }
  }
  .info2 {
    width: 180px;
    height: 100%;
    margin-left: 20px;
    img {
      margin: 20px auto;
      display: block;
      width: 172px;
      height: 162px;
    }
  }
  .info3 {
    width: 200px;
    height: 100%;
    padding-right: 25px;
    text-align: center;
    .p1 {
      margin-top: 20px;
      font-family: PingFangSC-Regular;
      font-size: 24px;
      color: #666666;
    }
    .p2 {
      margin-top: 4px;
      font-family: PingFangSC-Regular;
      font-size: 24px;
      color: #666666;
    }
    .exchangeBtn {
      width: 168px;
      height: 66px;
      margin-top: 20px;
      display: inline-block;
      background-color: #ee2e2e;
      border-radius: 33px;
      font-family: PingFangSC-Medium;
      font-size: 28px;
      color: #ffffff;
      line-height: 66px;
      text-align: center;
    }
  }
}
.exchangeBtn {

  width: 140px;
  height: 56px;
  margin-top: 16px;
  display: inline-block;
  background-color: #ee2e2e;
  font-size: 24px;
  color: #ffffff;
  border-radius: 28px;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
}
.signBoxBu{
  height: 310px !important;
}
.daySign {
  height: 340px !important;
  .itemBox {
    margin-left: 0 !important;
    margin-top: 32px !important;
    padding: 0 12px;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      margin: 0 2px;
      width: 72px;
      height: 100px;
      .coins {
        display: inline-block;
        width: 64px;
        height: 64px;
        margin: 5px 2px;
        line-height: 64px;
        border-radius: 50%;
        text-align: center;
        background-color: #f4f4f4;
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #c8c8c8;
      }
      .signed {
        width: 72px;
        margin: 2px 0;
        display: block;
      }
      .day {
        margin-top: 10px;
        height: 30px;
        width: 72px;
        display: inline-block;
        text-align: center;
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #999999;
      }
    }
  }
  .signBtn {
    margin-top: 26px !important;
  }
}
.signBox {
  width: calc(100% - 48px);
  height: 340px;
  margin: 24px 24px;
  background-color: #fff;
  border-radius: 20px;
  .div_buqian{
    margin-top: 24px;
    .div_content{
      margin:0 16px;
      .div_title{
        display: flex;
        align-items: center;
        font-size: 28px;
        margin-bottom: 20px;
        span{
          font-size: 24px;
          margin-left: 4px;
          color: #ee2e2e;
        }
      }
      .div_tip{
        font-size: 24px;
        color: #A4A4A4;
      }
    }
    img{
      width: 80px;
      height: 80px;
    }
    div{

    }
  }
  .div_card_top{
    padding:12px 0 20px 0;
    margin: 0 36px;
    border-bottom: 1px solid #F8F8F8;
    .div_card{
      img{
        width: 40px;
      }
      span{
        margin-left: 8px;
        color: #96561D;
        font-weight: 600;
      }
    }
  }
  .p1 {
    padding-top: 20px;
    padding-left: 30px;
    font-family: PingFangSC-Medium;
    font-size: 32px;
    color: #333333;
  }
  .itemBox {
    width: calc(100% - 60px);
    height: 100px;
    margin-left: 30px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .item {
      margin: 0 10px;
      width: 60px;
      height: 100px;
      .coins {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 30px;
        text-align: center;
        background-color: #f4f4f4;
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #c8c8c8;
      }
      .signed {
        width: 60px;
        display: block;
      }
      .day {
        margin-top: 10px;
        height: 30px;
        width: 60px;
        display: inline-block;
        text-align: center;
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #999999;
      }
    }
  }

}
.signBtn {
  margin-top: 26px;
  text-align: center;
  .signedBtn {
    display: inline-block;
    width: 446px;
    height: 70px;
    background-color: #ff9c9a;
    border-radius: 35px;
    font-family: PingFangSC-Medium;
    line-height: 70px;
    font-size: 28px;
    color: #ffffff;
  }
  .signBtn1 {
    display: inline-block;
    width: 446px;
    height: 70px;
    background-color: #ee2e2e;
    border-radius: 35px;
    font-family: PingFangSC-Medium;
    line-height: 70px;
    font-size: 28px;
    color: #ffffff;
  }
}
.taskBox {
  width: calc(100% - 108px);
  margin: 24px 24px;
  background-color: #fff;
  border-radius: 20px;
  padding: 0 30px;
  .top {
    padding: 30px 0;
    p {
      font-family: PingFangSC-Medium;
      font-size: 32px;
      color: #222222;
    }
  }
  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 0;
    border-top: 1px solid #f1f1f1;
    .left {
      margin-left: 10px;
      width: 60px;
      img {
        width: 60px;
        display: block;
      }
    }
    .middle {
      margin-left: 30px;
      width: calc(100% - 245px);
      .title {
        font-family: PingFangSC-Medium;
        font-size: 28px;
        color: #333333;
        .get {
          font-family: PingFangSC-Regular;
          font-size: 24px;
          color: #ee2e2e;
        }
      }
      .info {
        margin-top: 8px;
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #999999;
      }
    }
    .right {
      width: 145px;
      .doBtn {
        display: inline-block;
        width: 144px;
        height: 56px;
        font-family: PingFangSC-Regular;
        text-align: center;
        line-height: 56px;
        font-size: 24px;
        color: #ffffff;
        border-radius: 28px;
        background-color: #ee2e2e;
      }
      .doneBtn {
        display: inline-block;
        width: 144px;
        height: 56px;
        font-family: PingFangSC-Regular;
        text-align: center;
        line-height: 56px;
        font-size: 24px;
        color: #999999;
        border-radius: 28px;
        background-color: #f4f4f4;
      }
    }
  }
}
/deep/.van-popup--center {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0) !important;
}
.qrcode_box {
  .qrcode_box_bg {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 0;
    .qrcode_img {
      display: block;
      margin: 0;
      padding: 0 120px;
      width: 340px;
    }
    .qrcode_text {
      font-family: PingFangSC-Semibold;
      font-size: 34px;
      color: #333333;
      padding: 20px 0 10px;
      letter-spacing: 0;
      line-height: 50px;
      text-align: center;
    }
    .qrcode_text1 {
      font-family: PingFangSC-Semibold;
      font-size: 34px;
      color: #ee2e2e;
      padding: 20px 0;
      letter-spacing: 0;
      line-height: 50px;
      text-align: center;
    }
  }
  .close {
    margin-top: 60px;
    width: 100%;
    img {
      margin-left: calc((100% - 72px) / 2);
      width: 72px;
    }
  }
}
.wrapper1 {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: start;
  img{
    height: 200px;
    margin-right: 48px;
    margin-top: 20px;
  }
}
/deep/.van-overlay{
  z-index: 1001;
}
.img_card{
  width: 485px;
  border-radius: 16px;
  background: pink;
}
.dialog-header{
  .title{
    width: 240px;
    font-size: 30px;
    margin-top: 26px;
  }

}
/deep/.el-dialog__header{
  padding: 20px 20px 15px 30px;
  .el-dialog__headerbtn{
    top: 8px;
    right: 16px;
    font-size: 40px;
  }
}
/deep/.el-dialog__body{
  padding: 20px 20px;
}
</style>
